@import 'variables';
@import 'mixins';

.login h1 a {
	width: 200px;
	height: 84px;
	background-size: contain;
	background-position: bottom center;
  	background-image: url(../images/logo.png);
}
